/* eslint-disable camelcase  */

import {
  Card,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TableContainer,
  TablePagination,
  Typography,
  Button,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
} from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Scrollbar from '../components/Scrollbar';
import Page from '../components/Page';
import useAxiosWithAuth from '../api/useAxiosWithAuth';

function convertToDate(dotNetDate) {
  // Extract the number from the string
  const timestamp = parseInt(dotNetDate.replace(/\/Date\((-?\d+)\)\//, '$1'), 10);

  // Create a new Date object using the timestamp
  const date = new Date(timestamp);

  const options = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  return date.toLocaleString('en-GB', options);
}

const gridOptions = {
  icons: {
    sortAscending:
      '<span class="custom-sort-icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-up-narrow-wide"><path d="m3 8 4-4 4 4"/><path d="M7 4v16"/><path d="M11 12h4"/><path d="M11 16h7"/><path d="M11 20h10"/></svg></span>', // Ascending icon
    sortDescending:
      '<span class="custom-sort-icon"><svg xmlns="http://www.w3.org/2000/svg" width=16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-down-narrow-wide"><path d="m3 16 4 4 4-4"/><path d="M7 20V4"/><path d="M11 4h4"/><path d="M11 8h7"/><path d="M11 12h10"/></svg></span>', // Descending icon
    sortUnSort: '<span class="custom-sort-icon">-</span>', // Default unsorted icon
  },
};

const SupplierReportRedesigned = () => {
  const [columnDefs, setColumnDefs] = useState([
    {
      resizable: true,
      field: 'order_id',
      headerName: 'Woo Order ID',
      width: 200,
      cellRenderer: (params) => <span style={{ paddingLeft: '15px' }}>{params.value || 'N/A'}</span>,
    },
    {
      resizable: true,
      field: 'date_completed',
      headerName: 'Date Completed',
      type: 'centerAligned',
      width: 200,
      sortable: true,
      cellRenderer: (params) => (
        <span style={{ paddingLeft: '15px', display: 'block', width: '100%' }}>
          {params.value
            ? `${new Date(params.value).toLocaleDateString()} ${new Date(params.value).toLocaleTimeString()}`
            : 'N/A'}
        </span>
      ),
    },
    {
      resizable: true,
      field: 'product_code',
      headerName: 'Product Code',
      width: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      pinned: 'left',
      cellRenderer: (params) => <span style={{ paddingLeft: '15px' }}>{params.value || 'N/A'}</span>,
    },
    {
      resizable: true,
      field: 'pharmacy_name',
      headerName: 'Customer Name',
      width: 200,
      cellRenderer: (params) => <span style={{ paddingLeft: '15px' }}>{params.value || 'N/A'}</span>,
    },
    {
      resizable: true,
      field: 'address',
      headerName: 'Customer Address',
      width: 350,
      cellRenderer: (params) => (
        <div style={{ paddingLeft: '15px', display: 'flex', flexDirection: 'column', fontSize: '0.7rem' }}>
          <span style={{ textWrap: 'wrap' }}>
            {params.value.address_1 || ''}, {params.value.address_2 || ''}
          </span>
          <span style={{ textWrap: 'wrap' }}>
            {params.value.city || ''}, {params.value.state || ''} {params.value.postcode || ''}
          </span>
          <span style={{ textWrap: 'wrap' }}>{params.value.country || ''}</span>
        </div>
      ),
    },
    // {
    //   resizable: true,
    //   field: 'approved_status_changed',
    //   headerName: 'Status Change Date',
    //   width: 200,
    //   cellRenderer: (params) => (
    //     <span style={{ paddingLeft: '15px', display: 'block', width: '100%' }}>
    //       {params.value ? params.value.split(' ')[0] : 'N/A'}
    //     </span>
    //   ),
    // },
    {
      resizable: true,
      field: 'product_description',
      headerName: 'Product Description',
      width: 250,
      cellRenderer: (params) => <span style={{ paddingLeft: '15px' }}>{params.value || 'N/A'}</span>,
    },
    // {
    //   resizable: true,
    //   field: 'is_validated',
    //   headerName: 'Status',
    //   width: 200,
    //   cellRenderer: (params) => (
    //     <span style={{ paddingLeft: '15px' }}>{params.value ? 'Approved' : 'Not Approved'}</span>
    //   ),
    // },

    {
      resizable: true,
      field: 'quantity',
      headerName: 'Qty of Sold Products',
      width: 200,
      cellRenderer: (params) => (
        <span style={{ paddingLeft: '15px', display: 'block', width: '100%', textAlign: 'center' }}>
          {params.value || 'N/A'}
        </span>
      ),
    },
    {
      resizable: true,
      field: 'whole_sale_price',
      headerName: 'Whole Sale Price',
      width: 200,
      cellRenderer: (params) => (
        <span style={{ paddingLeft: '15px', display: 'block', textAlign: 'center' }}>
          {params.value.toFixed(2) || 'N/A'}
        </span>
      ),
    },
    {
      resizable: true,
      field: 'product_batch_number',
      headerName: 'Batch No',
      width: 200,
      cellRenderer: (params) => <span style={{ paddingLeft: '15px' }}>{params.value || 'N/A'}</span>,
    },
    {
      resizable: true,
      field: 'prescribing_doctor',
      headerName: 'Prescribing Doctor',
      width: 200,
      cellRenderer: (params) => <span style={{ paddingLeft: '15px' }}>{params.value || 'N/A'}</span>,
    },
    {
      resizable: true,
      field: 'tga_number',
      headerName: 'TGA Approval Number',
      width: 300,
      cellRenderer: (params) => <span style={{ paddingLeft: '15px' }}>{params.value || 'N/A'}</span>,
    },
    {
      resizable: true,
      field: 'action',
      headerName: 'Action',
      width: 120,
      pinned: 'right',
      cellStyle: {
        borderLeft: '1px solid #e0e0e0',
      },
      cellRenderer: (params) => (
        <Stack sx={{ padding: '0px 10px' }} direction={'row'} gap={2} justifyContent={'start'} alignItems={'center'}>
          {!params.data.is_validated ? (
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                setActionType('approve');
                setSelectedRows([params.data]);
                setOpenDialog(true);
              }}
            >
              Approve
            </Button>
          ) : (
            <Button
              variant="contained"
              size="small"
              // onClick={() => {
              //   setActionType('disapprove');
              //   setSelectedRows([params.data]);
              //   setOpenDialog(true);
              // }}
              disabled
            >
              Approved
            </Button>
          )}
        </Stack>
      ),
    },
  ]);

  const { post, put, get } = useAxiosWithAuth();
  const [stockReport, setStockReport] = useState([]);

  const maxDate = new Date().toISOString().split('T')[0];

  const date = new Date().toISOString().slice(0, 7);
  // const firstDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth(), 1, 12);
  // const lastDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0, 12);

  // const formattedFirstDate = firstDayOfCurrentMonth.toISOString().slice(0, 10);
  // const formattedLastDate = lastDayOfCurrentMonth.toISOString().slice(0, 10);

  const [reportDate, setReportDate] = useState(date);
  const [reportEndDate, setReportEndDate] = useState(date);
  const [isLoading, setIsLoading] = useState(true);
  const [isBatchLoading, setIsBatchLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);

  const [selectedProductCode, setSelectedProductCode] = useState('');
  const [currentProductCode, setCurrentProductCode] = useState('');

  const [openDialog, setOpenDialog] = React.useState(false);
  const [openBatchDialog, setOpenBatchDialog] = React.useState(false);

  const [batchInfo, setBatchInfo] = React.useState([]);

  const [actionType, setActionType] = React.useState('approve');

  const [approveStatus, setApproveStatus] = React.useState(1);

  const handleChange = (event) => {
    setApproveStatus(event.target.value);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const { user } = useAuth0();

  const approveStatusApi = async (payload) => {
    try {
      setIsLoading(true);
      const res = await post(`product/validate-orders`, { modify_orders: payload });
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    } finally {
      setShouldRefresh(true);
      setOpenDialog(false);
      setSelectedRows([]);
    }
  };

  const disapproveStatusApi = async (payload) => {
    try {
      setIsLoading(true);
      const res = await post(`product/invalidate-orders`, { modify_orders: payload });
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    } finally {
      setShouldRefresh(true);
      setOpenDialog(false);
      setSelectedRows([]);
    }
  };

  const handleDialogAction = () => {
    const payload = selectedRows.map((row) => ({ order_id: row.order_id, line_id: row.line_item_id }));
    if (actionType === 'approve') {
      approveStatusApi(payload);
    } else {
      disapproveStatusApi(payload);
    }
  };

  useEffect(() => {
    const getReport = async () => {
      try {
        const res = await post(
          `product/customer-validation-report/${user['http://healthhouse.com/supplier_code']}?page_size=${rowsPerPage}&page_number=${page}`,
          { approve_status: approveStatus }
        );
        setStockReport(res.data.payload);
        setTotal(res.data.meta.total_size);
        setStockReport(res.data.payload);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      } finally {
        setShouldRefresh(false);
      }
    };

    getReport();
  }, [reportDate, reportEndDate, page, rowsPerPage, user, shouldRefresh, approveStatus]);

  useEffect(() => {
    const batchInfo = async () => {
      setIsBatchLoading(true);
      try {
        const res = await get(`batch/batches-by-sku/${selectedProductCode}`);
        setBatchInfo(res.data.payload);
        setIsBatchLoading(false);
      } catch (err) {
        console.log(err);
        setIsBatchLoading(false);
      } finally {
        setIsBatchLoading(false);
      }
    };

    batchInfo();
  }, [user, selectedProductCode]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Page title="Stock Report">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} ml={2}>
          <Typography variant="h4" gutterBottom>
            Customer Validation Report
          </Typography>
          {selectedRows.length > 0 && (
            <Stack direction={'row'} gap={2}>
              <Button
                variant="contained"
                onClick={() => {
                  setOpenDialog(true);
                  setActionType('approve');
                }}
              >
                Approve All
              </Button>
              {/* <Button
                variant="contained"
                onClick={() => {
                  setOpenDialog(true);
                  setActionType('disapprove');
                }}
              >
                Disapprove All
              </Button> */}
            </Stack>
          )}
        </Stack>
        <Stack alignItems={'flex-end'} sx={{ my: 3 }}>
          <FormControl sx={{ width: 200 }} size="small">
            <InputLabel id="demo-simple-select-label">Approve Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={approveStatus}
              label="Approve Status"
              onChange={handleChange}
            >
              <MenuItem value={1}>All</MenuItem>
              <MenuItem value={2}>Approved Only</MenuItem>
              <MenuItem value={3}>Disapproved Only</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, maxHeight: 550, p: 3 }}>
              <div className="ag-theme-material" style={{ height: 500, minWidth: 750 }}>
                {isLoading ? (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <CircularProgress size={72} sx={{ mb: 3 }} />
                    <h5>Please wait while we prepare your data</h5>
                  </div>
                ) : (
                  <AgGridReact
                    rowData={stockReport}
                    // rowData={demoData}
                    columnDefs={columnDefs}
                    rowSelection="multiple"
                    onSelectionChanged={(e) => {
                      setSelectedRows(e.api.getSelectedRows());
                    }}
                    isRowSelectable={(rowNode) => !rowNode?.data?.is_validated}
                    onSortChanged={(params) => {
                      console.log({ params });
                    }}
                    gridOptions={gridOptions}
                  />
                )}
              </div>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Change Status?</DialogTitle>
        <DialogContent>
          <DialogContentText>Please be absolutely sure before changing the status.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleDialogAction}>Update</Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default SupplierReportRedesigned;
